


























import Vue from 'vue';
import tabs from '../tabs.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'engineeringProject',
  data() {
    return {
      tabs: [
        {
          label: '工程项目总价表',
          name: 'projectTotalPrice',
        },
        {
          label: '单项工程费汇总',
          name: 'singleProjectSummary',
        },
        {
          label: '报表',
          name: 'reportForms',
        },
        {
          label: '人材机汇总',
          name: 'artificialAndMechanicalSummary',
        },
      ],
      componentName: 'projectTotalPrice',
      componentHeight: 0,
    };
  },
  computed: {
    ...mapGetters(['toSeeReportForms', 'artificialSummary']),
  },
  watch: {
    artificialSummary: function (val) {
      if (val) {
        this.componentName = 'artificialAndMechanicalSummary';
      }
    },
  },
  created() {
    if (this.artificialSummary)
      this.componentName = 'artificialAndMechanicalSummary';
    this.setComponentHeight();
    window.addEventListener('resize', this.setComponentHeight);
  },
  methods: {
    setComponentHeight() {
      this.$nextTick(() => {
        const projectEl = this.$refs.engineeringProject as HTMLDivElement;
        const totalHeight = projectEl.clientHeight;
        const tabCom = this.$refs.tabs as Vue;
        const tabEl = tabCom.$el as HTMLDivElement;
        const tabHeight = tabEl.clientHeight;
        this.componentHeight = totalHeight - tabHeight;
      });
    },
    updateTab(tab: string) {
      this.componentName = tab;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setComponentHeight);
  },
  components: {
    projectTotalPrice: () => import('./projectTotalPrice.vue'),
    singleProjectSummary: () => import('./singleProjectSummary.vue'),
    reportForms: () => import('./reportForms.vue'),
    artificialAndMechanicalSummary: () =>
      import('./artificialAndMechanicalSummary.vue'),
    tabs,
  },
});
